<template>
  <div id="contents" class="identification">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button
            class="btn_admin"
            @click="openModal"
            :disabled="!filterCheckPrint"
          >
            식별표 출력
          </button>
          <barcode-print-modal
            v-if="isBarcodeModalOpen"
            @onclose="closeModal"
            :printList="inputPrintList"
            :checkPage="checkPage"
          ></barcode-print-modal>
          <div
            class="modal_layer"
            v-if="isBarcodeModalOpen"
            @click="closeModal"
          ></div>
          <button type="button" class="btn_admin" @click="CloseThisPage()">
            닫기
          </button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">입고식별표 출력</a>
          </li>
        </ul>
      </div>
      <input-print-form v-if="tabIndex == 0"></input-print-form>
    </div>
  </div>
</template>

<script>
import InputPrintForm from '@/views/forms/Material/InputPrint/InputPrintForm';
import BarcodePrintModal from '@/layouts/components/BarcodePrintModal';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    InputPrintForm,
    BarcodePrintModal,
  },
  data() {
    return {
      check_print: false,
      isBarcodeModalOpen: false,
      checkPage: '입고식별표',
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromInputPrintPage',
      inputPrintList: 'getInputPrintListFromInputPrint',
    }),
    filterCheckPrint() {
      let found = null;
      if (this.inputPrintList) {
        found = this.inputPrintList.find(x => x.check_print);
      }
      return found == undefined ? false : true;
    },
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToInputPrintPage',
    }),
    openModal() {
      this.isBarcodeModalOpen = true;
    },
    closeModal() {
      this.isBarcodeModalOpen = false;
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitInputPrintPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
