<template>
  <div class="article">
    <div class="identification_table tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span class="option_txt">입고일자</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="startDate"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="endDate"
                @change="setEndDate($event)"
              />
            </div>
          </div>
          <div class="input_search">
            <input
              type="text"
              placeholder="원자재명 또는 규격 검색"
              :value="searchText"
              @input="setSearchText($event)"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filteredInputPrint.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 6" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>입고일자</th>
              <th>원자재명</th>
              <th>규격</th>
              <th>수량(단위)</th>
              <th>바코드 번호</th>
              <th>
                <button class="check_btn" @click="selectAll()">
                  식별표 선택
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(inputPrint, index) in filteredInputPrint"
              :key="inputPrint.id"
            >
              <td v-if="inputPrint.input_yn">
                {{ inputPrint.input_date | formatDateNoHours }}
              </td>
              <td v-else>
                입고예정<br />
                ({{ inputPrint.input_date }})
              </td>
              <td class="text_left">{{ inputPrint.material_name }}</td>
              <td class="text_left">{{ inputPrint.material_standard }}</td>
              <td class="text_right">
                {{
                  `${$makeComma(inputPrint.quantity)}(${getUnitName(index)})`
                }}
              </td>
              <td>{{ `M${inputPrint.barcode_num}` }}</td>
              <td>
                <div class="input_checkbox">
                  <label
                    class="chk_box"
                    :for="`checkbox${index}`"
                    :id="`checkbox${index}`"
                    :class="{ active: inputPrint.check_print }"
                    @click="checkPrint(index)"
                  >
                    <i class="fa fa-check"></i>
                  </label>
                  <input type="checkbox" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';

export default {
  data() {
    return {
      showModal: false,
      material: '',
      standard: '',
      quantity: '',
      purchase: '',
      // date: '',
      barcode: '',
      check_print: false,
      app: document.querySelector('#app'),
    };
  },
  mixins: [fetchMixin, modalMixin],
  computed: {
    ...mapGetters({
      startDate: 'getStartDateFromInputPrint',
      endDate: 'getEndDateFromInputPrint',
      // date: 'getDateFromInputPrint',
      searchText: 'getSearchTextFromInputPrint',
      inputPrintList: 'getInputPrintListFromInputPrint',
      Material: 'getMaterial',
      UnitCodes: 'getUnitCodes',
    }),
    filteredInputPrint() {
      const Hangul = require('hangul-js');
      let printList = this.inputPrintList;
      printList = printList
        .sort(
          (b, a) =>
            b.input_yn - a.input_yn ||
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
              Number(
                b.create_time
                  .replace(/-/gi, '')
                  .replace(/:/gi, '')
                  .replace(' ', ''),
              ),
        )
        .filter(
          x =>
            x.material_name.includes(this.searchText) ||
            Hangul.d(x.material_name, true)
              .map(x => x[0])
              .join('')
              .includes(this.searchText) ||
            x.material_standard.includes(this.searchText) ||
            Hangul.d(x.material_standard, true)
              .map(x => x[0])
              .join('')
              .includes(this.searchText),
        );
      return printList;
    },
  },
  methods: {
    selectAll() {
      if (this.filteredInputPrint.find(x => !x.check_print)) {
        this.filteredInputPrint.forEach(element => {
          element.check_print = true;
        });
      } else {
        this.filteredInputPrint.forEach(element => {
          element.check_print = !element.check_print;
        });
      }
    },
    checkPrint(index) {
      this.filteredInputPrint[index].check_print = !this.filteredInputPrint[
        index
      ].check_print;
    },
    setStartDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setStartDateToInputPrint', e.target.value);
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '입고일자 오류',
            `입고일자 조회기간 최후날짜(${this.endDate})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToInputPrint', e.target.value);
          // this.selectRow(-1);
          this.FETCH_INPUT_PRINT();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToInputPrint', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setEndDateToInputPrint', e.target.value);
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '입고일자 오류',
            `입고일자 조회기간 최초날짜(${this.startDate})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToInputPrint', e.target.value);
          // this.selectRow(-1);
          this.FETCH_INPUT_PRINT();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToInputPrint', yyyymmdd(new Date()));
      }
    },
    setSearchText(e) {
      this.$store.commit('setSearchTextToInputPrint', e.target.value);
    },
    getUnitName(index) {
      const inputPrintTemp = this.inputPrintList[index].material_id;
      const findMId = this.findInfoFromId(this.Material, inputPrintTemp);
      if (findMId !== '') {
        const findUId = this.findInfoFromId(
          this.UnitCodes,
          findMId.incoming_unit_id,
        );
        return findUId != '' ? findUId.name : '';
      } else {
        return '';
      }
    },
    async FETCH_INPUT_PRINT() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_INPUT_PRINT', {
          start: this.startDate,
          end: this.endDate,
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '식별표 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.startDate == null) {
      var date = new Date();
      this.$store.commit('setEndDateToInputPrint', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToInputPrint', yyyymmdd(date2));
    }

    await this.FETCH_INPUT_PRINT();
    if (this.Material < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.UnitCodes < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
  },
};
</script>

<style lang="scss" scoped></style>
